import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import kvmVirtualizor from "/src/assets/images/kvm-virtualizor.png"
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const VpsServers = () => {
    return (
        <Layout>
            <SEO title="Enjoy the benefits offered by a VPS server with SiteBunker.net - VPS Hosting"
                 description="Enjoy dedicated KVM resources for any VPS server available at Sitebunker.net. Contact us for details and find out the included hosting benefits."
            />
            <Navbar/>
            <IntroHostingBgWhite
                title={'VPS Servers with KVM virtualization and NVMe storage'}
                subtitle={'Choose from VPS Server packages below with dedicated resources and unlimited traffic or we can customize a package for your needs.'}
            />

            <div className="vps-servers">
                <HostingPlanBlock category="VPS EN"
                                  discount='20'
                                  showPromos={false}/>
                <BannerArea
                    pageTitle="VPS Servers"
                    pageSubtitle="At SiteBunker you can enjoy your own VPS SERVER powered by AMD EPYC processors with NVMe full-SSD storage in RAID 10."
                    pageDescription="Dual socket servers, AMD Epyc processors, 1TB RAM, optimized at the highest stable frequency are now at your disposal at predictable costs. Contact the support team and ask for more details."
                    svgIllustration="server-vps"
                    logo={kvmVirtualizor}
                />
            </div>
            <PromoInfoRowParams
                title1="High-performance VPS server"
                title2="Advanced security and protection"
                paragraph1="SiteBunker’s VPS servers are optimized for high performance and guarantee the best option for storage space, CPU and allocated RAM. Safely host your web applications, presentation sites, blogs, various mail servers or games with us."
                paragraph2="Alongside the 24/7 monitorization and the optimization at the highest stable frequency, all SiteBunker VPS servers benefit from an advanced security with extra implemented protection for DDoS attacks."
            />
            <div className="remove-top-padding">
                <InfoBlocks/>
            </div>
            <ClientFeedback
                title="Find out more about "
                bold=" SiteBunker customers' reviews!"/>
            <SiteBenefitsParams
                mainTitle="VPS SERVER WITH PREMIUM RESOURCES INCLUDED"
                title1="OPTIONALLY DAILY BACKUP"
                title2="HIGH PERFORMANT SECURITY"
                title3="24/7 SUPPORT"
                text1="No matter the VPS server you choose to order from SiteBunker, we can offer you an automatic daily backup option in an external location. CONTACT US for pricing."
                text2="With SiteBunker, you benefit from enhanced data security and virtualization, along with the KVM virtualization needed for resource distribution."
                text3="We provide you 24/7 customer support. Get in touch with our team using the online ticketing system and we will shortly respond with solutions for any technical challenge."/>
            <CustomerSupportParams
                title="Discover the VPS hosting benefits at SiteBunker"
                paragraph1="Now you can enjoy the benefits of SiteBunker partnerships."
                paragraph2="  Contact the support team and order a VPS hosting. Find out more details about reseller hosting here."
                buttonText="Ask For Details"
            />
            <Footer/>
        </Layout>
    )
}

export default VpsServers

import React from "react"
import { Link } from "gatsby"
import SvgTehnical from "../../assets/svgComponents/tehnical-assistance.svg"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"


const CustomerSupportParams = ({ title, paragraph1, paragraph2, buttonText, secondaryLink, differentLink }) => {
  return (
    <section id="customer-support">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={techImg} alt="Web Hosting" loading="lazy"/>
            </div>
          </div>
          <div className="col-md-6 info-text">
            <h2 className="mb-5 h3-title">{title}</h2>
            <p>{paragraph1}</p>
            <p className="mb-5 "><span>{paragraph2} </span><span
              className={`${secondaryLink ? "d-inline-block" : "d-none"}`}><Link
              to="/gazduire-reseller-ssd/"> {secondaryLink}</Link></span></p>
            <Link to={differentLink ? differentLink : "/contact/"} className="view-more-button">{buttonText}</Link>
          </div>
        </div>
      </div>
    </section>
  )
}


export default CustomerSupportParams
